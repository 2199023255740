@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@500;700&display=swap');

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  background-color: black;
  color: white;
}

.section-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.left {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right {
  background-image: url(../assets/music-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.whatever {
  font-size: 50px;
  width: 80%;
  margin: auto;
  font-weight: bold;
}

.another-one {
  font-size: 15px;
  width: 80%;
  margin: auto;
}

.logo-container {
  width: 40px;
  position: absolute;
  top: 10px;
  left: 10px;
  display: grid;
  grid-template-columns: 45px 100px;
}

.logo {
  width: 40px;
  margin: auto;
}

.button-container {
  margin-top: 5%;
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: row;
}

#primaryButton {
  font-family: 'Raleway', sans-serif;
  color: white;
  font-weight: bold;
  border: #1DB954 1px solid;
  background-color: transparent;
  border-radius: 100px;
  width: 238px;
  padding: 5px;
  font-size: 0.9em;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.2s ease-out;
}

#primaryButton:hover {
  background-color: #1DB954;
}

a {
  color: lightgray;
}
a:hover {
  color: #1DB954;
}
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.beta {
  font-weight: bold;
  background-color: #f59b23;
  padding: 5px;
  border-radius: 5px;
  height: 20px;
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 15px;
}

@media only screen and (max-width: 1200px) {
  .whatever {
    font-size: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .whatever {
    font-size: 30px;
    text-align: center;
  }

  .button-container {
    text-align: center;
    align-items: center;
    justify-content: center;
  }

  .another-one {
    text-align: center;
  }

  .section-container {
    display: block;
    grid-template-columns: 50% 50%;
  }

  .left {
    height: 70vh;
  }

  .right {
    height: 30vh;
  }

  .beta {
    height: 12px;
    font-size: 10px;
  }
}
